import { defineStore } from 'pinia';
import { type IToggle, UnleashClient } from 'unleash-proxy-client';

export const useFeaturesStore = defineStore('featureFlags', () => {
    const flags = ref<Partial<Record<FeatureFlag, boolean>>>({});

    const config = useRuntimeConfig().public;
    const features = config.features as any;
    const initialised = ref<boolean>(false);
    const client = new UnleashClient({
        ...features,
        context: {
            properties: {
                deployment: 'localhost',
            },
        },
    });

    client.start().then(() => {
        initialised.value = true;
    });

    client.on('update', () => {
        const toggles = client.getAllToggles();
        flags.value = toggles.reduce((acc: Partial<Record<FeatureFlag, boolean>>, flag: IToggle) => {
            acc[flag.name as FeatureFlag] = flag.enabled;
            return acc;
        }, {});
    });

    const isEnabled = (feature: FeatureFlag) => computed(() => !!flags.value?.[feature]);

    const areAnyEnabled = (features: FeatureFlag[]) =>
        computed(() => initialised.value && features.some((feature) => isEnabled(feature)));

    const areAllEnabled = (features: FeatureFlag[]) =>
        computed(() => initialised.value && features.every((feature) => isEnabled(feature)));

    return { isEnabled, areAnyEnabled, areAllEnabled };
});
