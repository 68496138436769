import revive_payload_client_qzzcJl6fZO from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._6eql5quvev5b5mjkcqhmvh76um/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_umqhRbQf11 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._6eql5quvev5b5mjkcqhmvh76um/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_uj2wCuJ70s from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._6eql5quvev5b5mjkcqhmvh76um/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_8Q099gZkQ6 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._6eql5quvev5b5mjkcqhmvh76um/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1CIGHEvSvu from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._6eql5quvev5b5mjkcqhmvh76um/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IcFqVww3Ol from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._6eql5quvev5b5mjkcqhmvh76um/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_poJB0CItZl from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._6eql5quvev5b5mjkcqhmvh76um/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_LQwAaIUYys from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.2_rollup@4.18.1_typescript@5.4.2_vue@3.5.8_typescript@5.4.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_DLPoMHfrc0 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._6eql5quvev5b5mjkcqhmvh76um/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_S1wtI9NtWT from "/app/node_modules/.pnpm/dayjs-nuxt@2.1.11_rollup@4.18.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_e9yVtXNtY8 from "/app/node_modules/.pnpm/vue3-perfect-scrollbar@2.0.0_vue@3.5.8_typescript@5.4.2_/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import plugin_Hb1Du3T1zb from "/app/node_modules/.pnpm/nuxt-echarts@0.1.0_echarts@5.5.1_rollup@4.18.1/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import plugin_1TKANWxBnh from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.1_next-auth@4.21.1_next@13.5.6_@babel+core@7.24.7_@opentelemetry+api@_fe6vincnicgwo2eel6zlj6grtq/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_GqtFH1qE73 from "/app/node_modules/.pnpm/@primevue+nuxt-module@4.0.3_@babel+parser@7.26.3_rollup@4.18.1_vue@3.5.8_typescript@5.4.2_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import plugin_zA0vR0jcKY from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.2_rollup@4.18.1_typescript@5.4.2_vue@_zskhzipbvfqj5ahl4hhpl5zdsy/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import sentry_client_a4hAB0MOGi from "/app/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_qzzcJl6fZO,
  unhead_umqhRbQf11,
  router_uj2wCuJ70s,
  payload_client_8Q099gZkQ6,
  navigation_repaint_client_1CIGHEvSvu,
  check_outdated_build_client_IcFqVww3Ol,
  chunk_reload_client_poJB0CItZl,
  plugin_vue3_LQwAaIUYys,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DLPoMHfrc0,
  plugin_S1wtI9NtWT,
  plugin_e9yVtXNtY8,
  plugin_Hb1Du3T1zb,
  plugin_1TKANWxBnh,
  primevue_plugin_egKpok8Auk,
  plugin_client_GqtFH1qE73,
  plugin_zA0vR0jcKY,
  sentry_client_a4hAB0MOGi
]