import type { NitroFetchRequest } from 'nitropack';
import type { FetchOptions } from 'ofetch';

export const useApi = () => {
    const pendingRequests = ref<number>(0);
    const error = ref<Error | undefined>();
    const loading = computed(() => pendingRequests.value > 0);

    const API = <T = any>(url: string, options?: FetchOptions) => {
        const headers: HeadersInit = {
            Accept: 'application/json',
            'Cache-control': 'no-cache',
            ...options?.headers,
        };

        const opts: FetchOptions = options ? (({ headers, ...opts }) => opts)({ ...options }) : {};

        const fetch = $fetch.create<T, NitroFetchRequest>({
            baseURL: options?.baseURL,
            headers,
            ...opts,
            onRequest: (_ctx) => {
                pendingRequests.value += 1;
                error.value = undefined;
            },
            onResponse: (_ctx) => {
                pendingRequests.value -= 1;
            },
            onRequestError: (ctx) => {
                error.value = ctx.error;
            },
        });

        return fetch<T>(url);
    };

    // Syntactic sugar to simplify queries
    const $api = {
        get: <T = any>(url: string, opts?: FetchOptions) => API<T>(url, { method: 'GET', ...opts }),
        post: <T = any>(url: string, body?: any, opts?: FetchOptions) => API<T>(url, { method: 'POST', body, ...opts }),
        put: <T = any>(url: string, body?: any, opts?: FetchOptions) => API<T>(url, { method: 'PUT', body, ...opts }),
        patch: <T = any>(url: string, body?: any, opts?: FetchOptions) =>
            API<T>(url, { method: 'PATCH', body, ...opts }),
        delete: <T = any>(url: string, opts?: FetchOptions) => API<T>(url, { method: 'DELETE', ...opts }),
    };

    const DashboardsAPI = {
        index: (filter: DashboardFilter) => $api.get<Dashboard[]>('/api/dashboards', { query: { filter } }),
        get: (id: string) => $api.get<Dashboard>(`/api/dashboards/${id}`),
        getBySlug: (slug: string) => $api.get<PublishedDashboard>(`/api/dashboards/published/${slug}`),
        create: (configuration: DashboardConfiguration) => $api.post<Dashboard>('/api/dashboards', configuration),
        update: (id: string, configuration: Dashboard) => $api.put<Dashboard>(`/api/dashboards/${id}`, configuration),
        delete: (id: string) => $api.delete<Dashboard>(`/api/dashboards/${id}`),
        checkSlug: (slug: string) => $api.post<{ isAvailable: boolean }>('/api/dashboards/check-slug', { slug }),
        publish: (id: string, version: number | null) =>
            $api.post<any>(`/api/dashboards/${id}/${version ? `${version}/` : ''}publish`),
        discardChanges: (id: string) => $api.post<Dashboard>(`/api/dashboards/${id}/discard-changes`),
    };

    const PageAPI = {
        create: (dashboardId: string, configuration: DashboardPageConfiguration) =>
            $api.post<DashboardPage>(`/api/dashboards/${dashboardId}/pages`, configuration),
        update: (dashboardId: string, id: string, configuration: Partial<DashboardPageConfiguration>) =>
            $api.put<DashboardPage>(`/api/dashboards/${dashboardId}/pages/${id}`, configuration),
        delete: (id: string, pageId: string) => $api.delete<Dashboard>(`/api/dashboards/${id}/pages/${pageId}`),
        checkSlug: (dashboardId: string, slug: string) =>
            $api.post<{ isAvailable: boolean }>(`/api/dashboards/${dashboardId}/pages/check-slug`, { slug }),
    };

    const VisualisationAPI = {
        index: () => $api.get<Visualisation[]>('/api/visualisations'),
        get: (id: string) => $api.get<Visualisation>(`/api/visualisations/${id}`),
        create: (configuration: Visualisation) => $api.post<Visualisation>(`/api/visualisations`, configuration),
        update: (id: string, data: Visualisation) => $api.put<Visualisation>(`/api/visualisations/${id}`, data),
        delete: (id: string) => $api.delete<Visualisation>(`/api/visualisations/${id}`),
        addToPage: (dashboardId: string, pageId: string, id: string, configuration: Record<string, any>) =>
            $api.post<PageVisualisation>(
                `/api/dashboards/${dashboardId}/pages/${pageId}/visualisations/${id}`,
                configuration,
            ),
        updatePageVisualisationConfiguration: (
            dashboardId: string,
            pageId: string,
            id: number,
            configuration: Record<string, any>,
        ) =>
            $api.put<PageVisualisation>(
                `/api/dashboards/${dashboardId}/pages/${pageId}/page-visualisations/${id}`,
                configuration,
            ),
        removeFromPage: (dashboardId: string, pageId: string, id: number) =>
            $api.delete<Dashboard>(`/api/dashboards/${dashboardId}/pages/${pageId}/page-visualisations/${id}`),
    };

    const AssetAPI = {
        get: (id: number) => $api.get(`/api/assets/${id}`),
    };

    const SearchAPI = {
        search: (payload: SearchPayload, page: ESPageType, type: string) =>
            $api.post(`/api/search/${page}/${type}`, payload),
        findAssets: (payload: SearchPayload) => $api.post(`/api/search/assets`, payload),
    };

    const OrganisationAPI = {
        getUsers: (organisationId: number) => $api.get(`/api/organisations/${organisationId}/users`),
    };

    // Data Retrieval Service API. Requests proxied to the service by nitro server under /api/data-retrieval path
    const DataRetrievalAPI = {
        retrieve: (data: TransformedRetrievalType) => $api.post('/api/data-retrieval', data),
    };

    return {
        DashboardsAPI,
        PageAPI,
        VisualisationAPI,
        SearchAPI,
        OrganisationAPI,
        AssetAPI,
        DataRetrievalAPI,
        loading,
        error,
    };
};
